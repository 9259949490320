import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { srConfig, email, socialMedia } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import { PopupButton } from '@typeform/embed-react';
// import { createPopup } from '@typeform/embed';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--droom-blue);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link,
  .waitlist-button {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 20px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const [showMessage, setShowMessage] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const handleTypeformSubmit = () => {
    setShowMessage(true);
  };

  const handleLinkCopied = () => {
    setLinkCopied(true);
  };

  const copyLinkToClipboard = () => {
    const link = 'https://dreamplus.co.za/#contact';
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setLinkCopied(true);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">What’s Next?</h2>

      <h2 className="title">Coming Soon!</h2>

      <p>Join our channels for launch & giveaway updates!</p>

      {!linkCopied ? (
        <div>
          <button className="waitlist-button" onClick={copyLinkToClipboard}>
            Copy Link!
          </button>
        </div>
      ) : (
        <div>
          <button className="waitlist-button" onClick={copyLinkToClipboard}>
            ✅ Copy again!
          </button>
        </div>
      )}

      {!showMessage ? (
        <div>
          <PopupButton
            id="QjJUJ4Rb"
            className="waitlist-button"
            onSubmit={() => {
              setShowMessage(true);
            }}>
            Join Waitlist
          </PopupButton>
        </div>
      ) : (
        <div>
          <PopupButton
            id="QjJUJ4Rb"
            className="waitlist-button"
            onSubmit={() => {
              setShowMessage(true);
            }}>
            ✅ Join Waitlist
          </PopupButton>
        </div>
      )}

      {socialMedia.filter(x => x.name === 'Telegram')[0] ? (
        <div>
          <a className="email-link" href={socialMedia.filter(x => x.name === 'Telegram')[0].url}>
            Join Telegram
          </a>
        </div>
      ) : null}

      {socialMedia.filter(x => x.name === 'Instagram')[0] ? (
        <div>
          <a className="email-link" href={socialMedia.filter(x => x.name === 'Instagram')[0].url}>
            Follow on Instagram
          </a>
        </div>
      ) : null}

      {socialMedia.filter(x => x.name === 'Facebook')[0] ? (
        <div>
          <a className="email-link" href={socialMedia.filter(x => x.name === 'Facebook')[0].url}>
            Like & Follow on Facebook
          </a>
        </div>
      ) : null}
    </StyledContactSection>
  );
};

export default Contact;
