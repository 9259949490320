import React from 'react';

const IconTelegram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-telegram">
    <title>Telegram</title>
    <path d="M20 7.5l-2.8 11.1c-.2.7-.5.9-1 .7l-3.3-2.5-1.6 1.6c-.2.2-.4.4-.7.4l.3-4.4 7.7-7c.3-.3-.1-.5-.4-.2l-9.5 7-4.1-1.3c-.7-.2-.7-.7.1-.9l16.1-6.1c.6-.2 1.1.1.9 1.1z"></path>
  </svg>
);

export default IconTelegram;
